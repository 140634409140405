import React from "react";
import classes from "../Styles/Permisos.css";
import Input from "../../UI/Input/Input";
import { FormControl, FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const customInput = (elem, onChangeHandler) => {
  return (
    <Input
      key={elem.id}
      name={elem.name}
      elementType={elem.elementType}
      elementConfig={elem.elementConfig}
      value={elem.value}
      valueChanged={(event) => onChangeHandler(event, elem.id)}
      invalid={!elem.valid}
      shouldValidate={elem.validation}
      touched={elem.touched}
      errorMessage={elem.errorMessage}
      disabled={elem.disabled}
      style={elem.elementStyle}
      required={elem.required}
    />
  )
}

export const customCheckbox = (elem, onChangeHandler) => {
  return (
    <FormControlLabel
      key={elem.id}
      style={elem.style}
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />} 
          checked={elem.value} 
          onChange={(event) => onChangeHandler(event, elem.id)} 
          name={elem.name}
          disabled={elem.disabled}
        />
      }
      label={elem.label}
      classes={{
        label: classes.checkboxLabel,
      }}
    />
  )
}

export const customSelect = (elem, onChangeHandler) => {
  const description = `Seleccione ${elem.label}`;
  let value = elem.value;

  const onAutocompleteValueChange = (event, newValue) => {
    onChangeHandler(newValue, elem.id);
    value = newValue;
  }

  return (
    <FormControl className={classes.autocompleteRootControl} >
      <Autocomplete       
        classes={{
          root: classes.autocompleteRoot,
          input: classes.autocompleteInput,
          option: classes.autocompleteOptions,
        }}
        autoComplete={true}
        options={elem.items}
        getOptionLabel={(option) => option.nombre ? option.nombre.toUpperCase().trimEnd() : "" }
        renderInput={(params) => <TextField {...params} className={classes.autocompleteInput} placeholder={description.toUpperCase()} margin="normal" />}        
        disabled={elem.disabled}
        value={value}
        onChange={(event, newValue) => onAutocompleteValueChange(event, newValue)}
        size="small"
        style={{ marginBottom: '20px' }}
        required={elem.required}
      />
    </FormControl>
  )
}

export const customControls = (controls, onChangeHandler) => {
  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({ id: key, ...controls[key] });
  }

  let form = formElementsArray.map((elem) => {
    if(elem.elementType === "checkbox"){
      return customCheckbox(elem, onChangeHandler);
    }else if(elem.elementType === "select"){
      return customSelect(elem, onChangeHandler);
    }
    else {
      return customInput(elem, onChangeHandler);
    }
  });

  return form;
}

export const getCheckboxConfig = (name, label, value = false, disabled = false, style = null) => {  
  const config = {
    elementType: "checkbox",
    name: name,
    label: label,
    elementConfig: {
      type: "checkbox",
    },
    validation: {},
    disabled: disabled,
    value: value,
    valid: true,
    touched: false,
    errorMessage: "",
    style: style,
  };

  return config;
}