import React, { useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import classes from "./App.css";
import * as actions from "./store/actions";

import Layout from "./containers/Layout/Layout";

import esMessage from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import Logout from "./components/Logout/Logout";
import Permisos from "./containers/Permisos/Permisos";
import PerfilesClientes from "./containers/PerfilesClientes/PerfilesClientes";
import { userGauss } from "./assets/shared/sessionData";

const ListaApps = React.lazy(() => {
  return import("./components/ListApps/ListApps");
});

const ChangeLogs = React.lazy(() => {
  return import("./containers/ChangeLogs/ChangeLogs");
});
const ClientesGaussConfig = React.lazy(() => {
  return import("./containers/ClientesGaussConfig/ClientesGaussConfig");
});

const Login = React.lazy(() => {
  return import("./components/Login/Login");
});

const RecuperarPassword = React.lazy(() => {
  return import("./containers/Password/RecuperarPassword");
});

const CambiarPassword = React.lazy(() => {
  return import("./containers/Password/CambiarPassword");
});

const App = (props) => {
  const { onTryAuthSignin } = props;
  const isAdmin = sessionStorage.getItem("isAdmin") === "true";
  const sessionToken = sessionStorage.getItem("token");

  useEffect(() => {
    if (sessionToken) {
      onTryAuthSignin();
    }
  }, [onTryAuthSignin, sessionToken]);

  useEffect(() => {
    loadMessages(esMessage);
    locale(navigator.language);
    document.title = "Jalisco";
  }, []);

  //#region Unificacion login
  window.addEventListener("message", (event) => {
    if (typeof callbacks[event.data] === "function") callbacks[event.data]();
  });

  // Those callback functions will be triggered from parent frame.
  let callbacks = {
    setUserId: () => {
      if (localStorage.getItem("userId") === null) {
        // Generate random userId and store it in local storage
        localStorage.setItem("userId", Math.random().toString().substr(2));
      }
    },
    getUserId: () => {
      // Send user id to parent frame when its called.
      if (sessionStorage.getItem("token")) {
        window.parent.postMessage(sessionStorage.getItem("token"), "*");
      } else {
        window.parent.postMessage("sinToken", "*");
      }
    },
  };
  // Set User Id by default when frame loaded
  // It can be triggered from parent frame also
  callbacks.setUserId();
  //#endregion

  let routes = (
    <Switch>
      <Route path="/recuperarPassword" component={RecuperarPassword} />
      <Route path="/cambiarPassword" component={CambiarPassword} />
      <Route path="/" component={Login} />
    </Switch>
  );

  if (sessionToken !== null) {
    routes = (
      <Layout>
        <Switch>
          <Route path="/logout" component={Logout} />          
          <Route exact path="/" render={(props) => <ListaApps {...props} />} />
          <Route
            path="/clientes"
            render={(props) => <ClientesGaussConfig {...props} />}
          />
          {userGauss() && (<Route path="/administracionAplicaciones" render={(props) => <Permisos {...props} />} />)}
          {isAdmin && (<Route path="/perfilesClientes" render={(props) => <PerfilesClientes {...props} />} />)}
          {isAdmin && (
            <Route
              path="/changeLogs"
              render={(props) => <ChangeLogs {...props} />}
            />
          )}
          {props.token !== null ? <Redirect push to="/" /> : <Login />}
        </Switch>
      </Layout>
    );
  }

  return (
    <div className={classes.App}>
      <Suspense fallback={<div />}>{routes}</Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.usuarios.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuthSignin: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));