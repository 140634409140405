// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListUsersDataTable__ListUserDataTableContainer__ZtPWy{\r\n    margin: auto;\r\n}\r\n\r\n.ListUsersDataTable__ListUserDataTableContainer__ZtPWy div {\r\n    border-radius: 10px !important;\r\n}\r\n\r\n.ListUsersDataTable__ListUserTable__shtnb{    \r\n    max-width: -webkit-max-content;    \r\n    max-width: max-content;\r\n}\r\n\r\n.ListUsersDataTable__ListUserTableTitle__ZBfGP {\r\n    color: rgb(0, 188, 212);\r\n    font-size: 15px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListUserDataTableContainer": "ListUsersDataTable__ListUserDataTableContainer__ZtPWy",
	"ListUserTable": "ListUsersDataTable__ListUserTable__shtnb",
	"ListUserTableTitle": "ListUsersDataTable__ListUserTableTitle__ZBfGP"
};
export default ___CSS_LOADER_EXPORT___;
