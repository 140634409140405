// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalFormUpdateUser__titleContent__AyPQQ {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.ModalFormUpdateUser__titleTexto__--6ZN {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.ModalFormUpdateUser__FormUpdateUserContainer__D8p7x {\r\n  display: flex;\r\n  flex: 1 1;\r\n  width: 100%;\r\n  flex-direction: column;\r\n}\r\n\r\n.ModalFormUpdateUser__flexRow__2IEI7 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.ModalFormUpdateUser__rowItem__BN1lT {\r\n  margin-inline: 5px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContent": "ModalFormUpdateUser__titleContent__AyPQQ",
	"titleTexto": "ModalFormUpdateUser__titleTexto__--6ZN",
	"FormUpdateUserContainer": "ModalFormUpdateUser__FormUpdateUserContainer__D8p7x",
	"flexRow": "ModalFormUpdateUser__flexRow__2IEI7",
	"rowItem": "ModalFormUpdateUser__rowItem__BN1lT"
};
export default ___CSS_LOADER_EXPORT___;
