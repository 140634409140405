import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import * as actions from "../../../store/actions";
import Table from "../../UI/Table/Table";

import { Checkbox, FormControlLabel, Grid, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import Spinner from "../../UI/Spinner/Spinner";

import classes from "./TableDashboardUserConfig.css";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

const TableDashboardConfig = (props) => {
  const { items, loading, onLoadEmpresas, userId, guardarEmpresasAccesoUsuario } = props;

  const [tieneAcceso, setTieneAcceso] = useState(false);
  const [empresas, setEmpresas] = useState(items);
  const [loadingAccesos, setLoadingAccesos] = useState(false);
  const [inEdit, setInEdit] = useState(false);
  const [tempEmpresas, setTempEmpresas] = useState([]);

  const updateTieneAcceso = useCallback((empresas) => {
    let acceso = true;
    empresas.forEach((emp) => { acceso = acceso && emp.acceso });
    setTieneAcceso(acceso);
  }, [empresas, setTieneAcceso]);

  useEffect(() => {
    onLoadEmpresas(userId);      
  }, [userId, onLoadEmpresas]);

  useEffect(() => {
    setEmpresas(items);
  }, [items, setEmpresas]);

  useEffect(() => {
    updateTieneAcceso(empresas);
  }, [empresas, updateTieneAcceso]);

  const cellStyles = {    
    padding:0,
    height:42, 
    width: 50,
    fontSize: '14px',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyles = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const onEditClick = () => {
    var empresasTemp = JSON.parse(JSON.stringify(empresas));
    setTempEmpresas(empresasTemp);
    setInEdit(true);
  };

  const onSaveClick = () => {
    setLoadingAccesos(true);
    guardarEmpresasAccesoUsuario(empresas);
    setInEdit(false);
    setTimeout(() => setLoadingAccesos(false), 500);
  }

  const onCancelClick = () => {
    setLoadingAccesos(true);
    setEmpresas(tempEmpresas);
    setTempEmpresas([]);
    setInEdit(false);
    setTimeout(() => setLoadingAccesos(false), 500);
  };

  const headerActions = () => {
    return (
      <Grid container spacing={2}
        direction='row'
        justify='space-between'
        alignContent='center'
      >
        <Grid item md={6}>
          <FormControlLabel
            value="start"
            size="small"
            control={
              <Checkbox
                checked={tieneAcceso}
                color="primary"
                onChange={() => onTieneAccesoChange(!tieneAcceso)}
              />
            }
            label="Seleccionar todas"
            labelPlacement="end"
            disabled={!inEdit}
          />
        </Grid>
        {inEdit ? (
          <Grid item md={2} container>
            <Grid item md={6}>
              <Tooltip title='Cancelar cambios' placement='top'>
                <IconButton onClick={() => onCancelClick()}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <Tooltip title='Guardar cambios' placement='top'>
                <IconButton onClick={() => onSaveClick()}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid item md={1}>
            <Tooltip title='Editar' placement='top'>
              <IconButton onClick={() => onEditClick()}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}     
      </Grid>
    )
  }

  const empresaAccesoChange = (event, rowData) => {
    let tempEmpresas = empresas;

    tempEmpresas.forEach((empresa) => {
      if(empresa.idEmpresa === rowData.idEmpresa)
        empresa.acceso = event.target.checked;
    });
    setEmpresas(tempEmpresas);
    updateTieneAcceso(tempEmpresas);
  }

  const onTieneAccesoChange = (acceso) => {
    setLoadingAccesos(true);
    let tempEmpresas = empresas;

    tempEmpresas.forEach((emp) => emp.acceso = acceso);
    setEmpresas(tempEmpresas);
    setTieneAcceso(acceso);
    setTimeout(() => setLoadingAccesos(false), 500);
  }

  const columns = () => {
    return [
      {
        title: "Empresa",
        field: "nombreEmpresa",
        editable: "never",
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Acceso",
        field: "acceso",
        render: (rowData) => {
          return <EmpresaAccesoCheckbox 
            rowData={rowData}
            onAccesoChange={empresaAccesoChange}
            disabled={!inEdit}
          />;
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
    ];
  }

  const data = () => {
    return empresas.map((empresa) => ({
      idEmpresa: empresa.idEmpresa,
      nombreEmpresa: empresa.nombreEmpresa,
      acceso: empresa.acceso,
    }));
  };

  return loadingAccesos ? (
    <div className={classes.SpinnerEmpresas}>
      <Spinner />
    </div>
  ) : (
    <Fragment>
      {headerActions()}
      <div>
        <Table
          title={props.userEmail}
          data={data()}
          localization={{
            body: {
              emptyDataSourceMessage: tieneAcceso
                ? "No se encontraron empresas"
                : "El usuario no tiene acceso a la aplicacion",
            },
          }}
          loading={loading}
          columns={columns()}
          customOptions={{
            showTitle: true,
            actionsColumnIndex: -1,
            grouping: false,
            draggable: false,
            columnResizable: false,
            tableLayout: "auto",
            paging: false,
            search: false,
            sorting: false,
          }}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.configuraciones.dashboardUserConfig.userId,
    userEmail: state.configuraciones.dashboardUserConfig.userEmail,
    items: state.empresas.empresasAccesoUsuario.empresas,
    loading: state.empresas.empresasAccesoUsuario.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEmpresas: (userId) => dispatch(actions.loadEmpresasAccesoUsuario(userId)),
    guardarEmpresasAccesoUsuario: (data) => dispatch(actions.guardarEmpresasAccesoUsuario(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDashboardConfig);

const EmpresaAccesoCheckbox = (props) => {
  let { rowData, onAccesoChange, disabled } = props;

  const [checked, setChecked] = useState(rowData.acceso);

  const onCheckChange = (event) => {
    setChecked(event.target.checked);
    onAccesoChange(event, rowData);
  }

  return (
    <Checkbox 
      color="primary" 
      size="small" 
      checked={checked} 
      onChange={(e) => {onCheckChange(e)}}
      disabled={disabled}
    />
  );
}
