import React, { useState } from "react";
import * as actions from "../../../store/actions/index";

import { connect } from "react-redux";
import { checkValidity } from "../../../assets/shared/utility";
import Dialog from "../../UI/Dialog/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../UI/Button/Button";
import { IconButton, Typography } from "@material-ui/core";
import { customControls, getCheckboxConfig } from '../../Permisos/Common/Controls';
import { MenuItem, TextField } from "@material-ui/core";

import classes from "./ModalFormUpdateUser.css";

const FormUpdateUser = (props) => {
  const { 
    id, 
    name, 
    email, 
    show, 
    onClose, 
    onModificarUsuario,
    user, 
  } = props;

  const [perfilId, setPerfilId] = useState(user.perfilid);

  const [controls, setControls] = useState({
    name: {
      name: "name",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Nombre",
      },
      disabled: false,
      value: user.name,
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    surname: {
      name: "surname",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Apellido",
      },
      disabled: false,
      value: user.surname,
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    password: {
      name: "pass",
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Contraseña",
      },
      value: "",
      validation: {
        minLength: 6,
        containSimbol: true,
        containNumber: true,
        containMayus: true,
        containMinus: true,
      },
      valid: false,
      touched: false,
      errorMessage: "",
    },
    email: {
      name: "email",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Email",
      },
      value: user.email,
      validation: {
        required: true,
        isEmail: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    baja: getCheckboxConfig("baja", "Baja", user.baja, false, { width: '100%', marginLeft: '10px', marginRight: '20px' }),
  });

  const [formIsValid, setFormIsValid] = useState(email && name);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };

    const value = updatedControl.elementType === "checkbox" 
    ? event.target.checked 
    : updatedControl.elementType === "select" 
      ? event
      : event.target.value;

    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  let form = customControls(controls, inputValueChangedHandler);

  const body = () => {
    return (
      <div className={classes.FormUpdateUserContainer}>
        <div className={classes.flexRow}>                    
          {form[0]}
          <div style={{ width: '30px' }}></div>
          {form[1]}
          <div style={{ width: '30px' }}></div>
          {form[3]}
        </div>
        <div className={classes.flexRow}>
          {form[2]}
          <div style={{ width: '30px' }}></div>
          {form[4]}
          <div style={{ width: '30px' }}></div>
          <TextField
            id={`selectPerfil${user.id}`}
            select
            size="small"
            style={{ minWidth: '100px', width: '100%' }}
            value={perfilId}
            onChange={(event) => setPerfilId(event.target.value)}
          >
            {props.perfiles.map((perfil) => (
              <MenuItem 
                key={perfil.id} 
                value={perfil.id}
                style={{ fontSize: '12px' }}
              >
                {perfil.nombre}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.flexRow}>
          <div style={{ width: '66%' }}></div>
          <div style={{ width: '33%' }}>
            <Button
              disabled={!formIsValid}
              onClick={() => onGuardarCambiosClick()}
            >
              Guardar Cambios
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const onGuardarCambiosClick = () => {
    const usuario = {
      id: user.id,
      nombre: controls.name.value,
      apellido: controls.surname.value,
      email: controls.email.value,
      password: controls.password.value,
      baja: controls.baja.value,
      perfilId: perfilId,
    };

    const clienteId = sessionStorage.getItem("clienteId");

    onModificarUsuario(id, usuario, clienteId, () => { onClose(); });
  };

  return (
    <Dialog
      title={
        <div classes={classes.titleContent} style={{ width: "100%" }}>
          <div className={classes.titleTexto}>
            <Typography variant="h5">Modificar usuario</Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      }
      maxWidth="sm"
      fullWidth
      show={show}
      onClose={onClose}
      body={body()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    perfiles: state.permisos.loadPerfilesClienteAsignacion.items,
  }  
};

const mapDispatchToProps = (dispatch) => {
  return {
    onModificarUsuario: (userId, userModificado, clienteId, success) =>
      dispatch(actions.modificarUser(userId, userModificado, clienteId, success)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdateUser);
