import * as actionTypes from "./actionTypes";
import axios from "../../axios-instance";
import idIndicadores from "../../assets/shared/idIndicadores";
import { enqueueSnackbar } from './snackbar';

export const loadEmpresas = (idUser) => {
  return (dispatch) => {
    dispatch(loadEmpresasStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(
        `/empresas/EmpresasPorUsuarioConVendedor?idUser=${idUser}&indicadorId=${idIndicadores.empresasConVendedor}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadEmpresasSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadEmpresasFail(error));
      });
  };
};

const loadEmpresasStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_START,
  };
};

const loadEmpresasSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_SUCCESS,
    empresas,
  };
};

const loadEmpresasFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_FAIL,
    error,
  };
};

export const loadEmpresasDelCliente = (clienteId) => {
  return (dispatch) => {
    dispatch(loadEmpresasDelClienteStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/empresas/EmpresasDelCliente?clienteId=${clienteId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadEmpresasDelClienteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadEmpresasDelClienteFail(error));
      });
  };
};

export const clearEmpresasDelCliente = () => {
  return {
    type: actionTypes.CLEAR_EMPRESAS_DEL_CLIENTE
  };
}
const loadEmpresasDelClienteStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_DEL_CLIENTE_START,
  };
};

const loadEmpresasDelClienteSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_DEL_CLIENTE_SUCCESS,
    empresas,
  };
};

const loadEmpresasDelClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_DEL_CLIENTE_FAIL,
    error,
  };
};

export const loadEmpresasEiffelDelCliente = (clienteId) => {
  return (dispatch) => {
    dispatch(loadEmpresasEiffelDelClienteStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/SGC/EmpresasDelCliente?clienteId=${clienteId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadEmpresasEiffelDelClienteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadEmpresasEiffelDelClienteFail(error));
      });
  };
};

const loadEmpresasEiffelDelClienteStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_START,
  };
};

const loadEmpresasEiffelDelClienteSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_SUCCESS,
    empresas,
  };
};

const loadEmpresasEiffelDelClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_FAIL,
    error,
  };
};

export const crearEmpresa = (nuevaEmpresa, clienteId, success) => {
  return (dispatch) => {
    dispatch(executeCrearEmpresa(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .post(`/empresas`, nuevaEmpresa, {
        headers: { Authorization: authStr, "Content-Type": "application/json" },
      })
      .then((response) => {
        dispatch(loadEmpresasDelCliente(clienteId));
        dispatch(executeCrearEmpresa(false));
        return success();
      })
      .catch((error) => {
        dispatch(executeCrearEmpresa(false));
      });
  };
};

const executeCrearEmpresa = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_EMPRESA,
  };
};

export const modificarEmpresa = (empresaId, empresaModificada, clienteId) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .put(`/empresas?id=${empresaId}`, empresaModificada, {
        headers: { Authorization: authStr, "Content-Type": "application/json" },
      })
      .then((response) => {
        dispatch(loadEmpresasDelCliente(clienteId));
        dispatch(executeCrearEmpresa(false));
      })
      .catch((error) => {
        dispatch(executeCrearEmpresa(false));
      });
  };
};

export const loadEmpresasAccesoUsuario = (idUser) => {
  return (dispatch) => {
    dispatch(loadEmpresasAccesoUsuarioStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const clienteId = sessionStorage.getItem("clienteId");
    axios
      .get(
        `/empresas/EmpresasAccesoUsuario?idCliente=${clienteId}&idUser=${idUser}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadEmpresasAccesoUsuarioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadEmpresasAccesoUsuarioFail(error));
      });
  };
};

const loadEmpresasAccesoUsuarioStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_ACCESO_USUARIO_START,
  };
};

const loadEmpresasAccesoUsuarioSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_ACCESO_USUARIO_SUCCESS,
    empresas,
  };
};

const loadEmpresasAccesoUsuarioFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_ACCESO_USUARIO_FAIL,
    error,
  };
};

export const guardarEmpresasAccesoUsuario = (data) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .put('/empresas/GuardarEmpresasAccesoUsuario', data, {
        headers: { Authorization: authStr, "Content-Type": "application/json" },
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Datos de acceso guardados correctamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los datos de acceso.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};