import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  empresas: {
    loading: false,
    empresas: [],
    error: null,
  },
  empresasAccesoUsuario: {
    loading: false,
    empresas: [],
    error: null,
  },
  empresasDelCliente: {
    empresas: [],
    loading: false,
    error: null,
  },
  empresasEiffelDelCliente: {
    empresas: [],
    loading: false,
    error: null,
  },
};

//#region Load Empresas
const loadEmpresasStart = (state) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: true,
      empresas: [],
      error: null,
    }),
  });
};

const loadEmpresasSuccess = (state, empresas) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      empresas,
      loading: false,
    }),
  });
};

const loadEmpresasFail = (state, error) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: false,
      error,
    }),
  });
};
//#endregion

//#region Load Empresas Acceso Usuario
const loadEmpresasAccesoUsuarioStart = (state) => {
  return updateObject(state, {
    empresasAccesoUsuario: updateObject(state.empresasAccesoUsuario, {
      loading: true,
      empresas: [],
      error: null,
    }),
  });
};

const loadEmpresasAccesoUsuarioSuccess = (state, empresas) => {
  return updateObject(state, {
    empresasAccesoUsuario: updateObject(state.empresasAccesoUsuario, {
      empresas,
      loading: false,
    }),
  });
};

const loadEmpresasAccesoUsuarioFail = (state, error) => {
  return updateObject(state, {
    empresasAccesoUsuario: updateObject(state.empresasAccesoUsuario, {
      loading: false,
      error,
    }),
  });
};
//#endregion

//#region Load Empresas Del Cliente
const loadEmpresasDelClienteStart = (state) => {
  return updateObject(state, {
    empresasDelCliente: updateObject(state.empresasDelCliente, {
      loading: true,
    }),
  });
};

const loadEmpresasDelClienteSuccess = (state, empresas) => {
  return updateObject(state, {
    empresasDelCliente: updateObject(state.empresasDelCliente, {
      loading: false,
      empresas,
    }),
  });
};

const loadEmpresasDelClienteFail = (state, error) => {
  return updateObject(state, {
    empresasDelCliente: updateObject(state.empresasDelCliente, {
      loading: false,
      error,
      empresas: [],
    }),
  });
};

const clearEmpresasDelCliente = (state) => {
  return updateObject(state, {
    empresasDelCliente: updateObject(state.empresasDelCliente, {
      loading: false, 
      error: null, 
      empresas: []
    })
  })
}
//#endregion

//#region Load Empresas Eiffel Del Cliente
const loadEmpresasEiffelDelClienteStart = (state) => {
  return updateObject(state, {
    empresasEiffelDelCliente: updateObject(state.empresasEiffelDelCliente, {
      loading: true,
    }),
  });
};

const loadEmpresasEiffelDelClienteSuccess = (state, empresas) => {
  return updateObject(state, {
    empresasEiffelDelCliente: updateObject(state.empresasEiffelDelCliente, {
      loading: false,
      empresas,
    }),
  });
};

const loadEmpresasEiffelDelClienteFail = (state, error) => {
  return updateObject(state, {
    empresasEiffelDelCliente: updateObject(state.empresasEiffelDelCliente, {
      loading: false,
      error,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_EMPRESAS_START:
      return loadEmpresasStart(state);
    case actionTypes.LOAD_EMPRESAS_SUCCESS:
      return loadEmpresasSuccess(state, action.empresas);
    case actionTypes.LOAD_EMPRESAS_FAIL:
      return loadEmpresasFail(state, action.error);

    case actionTypes.LOAD_EMPRESAS_ACCESO_USUARIO_START:
      return loadEmpresasAccesoUsuarioStart(state);
    case actionTypes.LOAD_EMPRESAS_ACCESO_USUARIO_SUCCESS:
      return loadEmpresasAccesoUsuarioSuccess(state, action.empresas);
    case actionTypes.LOAD_EMPRESAS_ACCESO_USUARIO_FAIL:
      return loadEmpresasAccesoUsuarioFail(state, action.error);

    case actionTypes.LOAD_EMPRESAS_DEL_CLIENTE_START:
      return loadEmpresasDelClienteStart(state);
    case actionTypes.LOAD_EMPRESAS_DEL_CLIENTE_SUCCESS:
      return loadEmpresasDelClienteSuccess(state, action.empresas);
    case actionTypes.LOAD_EMPRESAS_DEL_CLIENTE_FAIL:
      return loadEmpresasDelClienteFail(state, action.error);
    case actionTypes.CLEAR_EMPRESAS_DEL_CLIENTE: 
      return clearEmpresasDelCliente(state); 

    case actionTypes.LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_START:
      return loadEmpresasEiffelDelClienteStart(state);
    case actionTypes.LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_SUCCESS:
      return loadEmpresasEiffelDelClienteSuccess(state, action.empresas);
    case actionTypes.LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_FAIL:
      return loadEmpresasEiffelDelClienteFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;
