import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  clientes: {
    clientes: [],
    loading: false,
    error: null,
  },
  clientSelected: {
    clienteId: "",
    clienteIdPg: 0,
    cuit: "",
    nombreFantasia: "",
    razonSocial: "",
    baja: false,
  },
};

//#region loadClientes
const loadClientesStart = (state) => {
  return updateObject(state, {
    clientes: updateObject(state.clientes, {
      loading: true,
    }),
  });
};

const loadClientesSuccess = (state, clientes) => {
  return updateObject(state, {
    clientes: updateObject(state.clientes, {
      clientes,
      loading: false,
    }),
  });
};

const loadClientesFail = (state, error) => {
  return updateObject(state, {
    clientes: updateObject(state.clientes, {
      loading: false,
      error,
    }),
  });
};
//#endregion

//#region Client selected
const clientSelected = (state, client) => {
  return updateObject(state, {
    clientSelected: updateObject(state.clientSelected, {
      clienteId: client.clienteId,
      clienteIdPg: client.clienteIdPg,
      cuit: client.cuit,
      nombreFantasia: client.nombreFantasia,
      razonSocial: client.razonSocial,
      baja: client.baja,
    }),
  });
};
//#endregion

//#region Actualizar cliente creado
const actualizarClienteCreado = (state, clienteCreado, index) => {
  state.clientes.clientes[index].clienteIdPg = clienteCreado.id;
  state.clientes.clientes[index].nombreFantasia = clienteCreado.nombre;
  state.clientes.clientes[index].baja = clienteCreado.baja;

  state.clientSelected.clienteIdPg = clienteCreado.id;
  state.clientSelected.baja = clienteCreado.baja;
  state.clientSelected.nombreFantasia = clienteCreado.nombre;

  return state;
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CLIENTES_START:
      return loadClientesStart(state);
    case actionTypes.LOAD_CLIENTES_SUCCESS:
      return loadClientesSuccess(state, action.clientes);
    case actionTypes.LOAD_CLIENTES_FAIL:
      return loadClientesFail(state, action.error);

    case actionTypes.CLIENT_SELECTED:
      return clientSelected(state, action.client);

    case actionTypes.ACTUALIZAR_CLIENTE_CREADO:
      return actualizarClienteCreado(state, action.clienteCreado, action.index);

    default:
      return state;
  }
};

export default reducer;
