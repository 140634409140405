export const checkValidity = (value, rules) => {
  let isValid = true;
  let errorMessage = "";
  if (rules) {
    if (isValid && rules.required) {
      if(typeof value === 'string'){
        isValid = value.trim() !== "";
        errorMessage = `El campo es requerido`;
      }

      if(typeof value === 'object'){
        isValid = value !== null && value !== undefined;
        errorMessage = `El campo es requerido`;
      }      
    }

    if (isValid && rules.minLength) {
      isValid = value.length >= rules.minLength;
      errorMessage = `El campo debe tener como mínimo ${rules.minLength} caracteres`;
    }

    if (isValid && rules.maxLength) {
      isValid = value.length <= rules.maxLength;
      errorMessage = `El campo debe tener como máximo ${rules.maxLength} caracteres`;
    }

    if (rules.isEmail) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = pattern.test(value) && isValid;
      if (!pattern.test(value))
        errorMessage = "El formato del email es incorrecto";
    }

    if (rules.isNumeric && isValid) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
      if (!pattern.test(value)) errorMessage = "El campo debe ser numérico";
    }

    if (rules.containSimbol) {
      const pattern = /[-+_!@#$%^&*.,?]/;
      isValid = pattern.test(value) && isValid;
      if (!pattern.test(value))
        errorMessage = "El campo debe tener al menos un simbolo";
    }

    if (rules.containNumber) {
      const pattern = /\d/;
      isValid = pattern.test(value) && isValid;
      if (!pattern.test(value))
        errorMessage = "El campo debe tener al menos un numero";
    }

    if (rules.containMayus) {
      const pattern = /[A-Z]/;
      isValid = pattern.test(value) && isValid;
      if (!pattern.test(value))
        errorMessage = "El campo debe tener al menos una mayuscula";
    }

    if (rules.containMinus) {
      const pattern = /[a-z]/;
      isValid = pattern.test(value) && isValid;
      if (!pattern.test(value))
        errorMessage = "El campo debe tener al menos una minuscula";
    }
  }

  return [isValid, errorMessage];
};

export const smallDays = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
export const days = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const dateToString = (date) => {
  const day = days[date.getDay()];
  const month = months[date.getMonth()];

  return day + " " + date.getDate() + " de " + month;
};

export const dateToCoreDate = (date) => {
  return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
};

export const groupBy = (list, keyGetter) => {
  const storage = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    storage[key] = storage[key] || [];
    storage[key].push(item);
  });
  return storage;
};

export const capitalizeName = (name) => {
  const mySentence = name.trimEnd().toLowerCase();
  const words = mySentence.split(" ");

  return words.map((word) => { 
      return word[0] ? word[0].toUpperCase() + word.substring(1) : word; 
  }).join(" ");
}
