export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_REDIRECT_PATH = "AUTH_SET_REDIRECT_PATH";
export const ELIMINAR_TOKEN = "ELIMINAR_TOKEN";

export const RECUPERAR_PASSWORD_START = "RECUPERAR_PASSWORD_START";
export const RECUPERAR_PASSWORD_SUCCESS = "RECUPERAR_PASSWORD_SUCCESS";
export const RECUPERAR_PASSWORD_FAIL = "RECUPERAR_PASSWORD_FAIL";
export const CLEAN_RECUPERAR_PASSWORD = "CLEAN_RECUPERAR_PASSWORD";

export const CAMBIAR_PASSWORD_START = "CAMBIAR_PASSWORD_START";
export const CAMBIAR_PASSWORD_SUCCESS = "CAMBIAR_PASSWORD_SUCCESS";
export const CAMBIAR_PASSWORD_FAIL = "CAMBIAR_PASSWORD_FAIL";
export const CLEAN_CAMBIAR_PASSWORD = "CLEAN_CAMBIAR_PASSWORD";

export const LOAD_VENDEDORES_START = "LOAD_VENDEDORES_START";
export const LOAD_VENDEDORES_SUCCESS = "LOAD_VENDEDORES_SUCCESS";
export const LOAD_VENDEDORES_FAIL = "LOAD_VENDEDORES_FAIL";

export const LOAD_EMPRESAS_START = "LOAD_EMPRESAS_START";
export const LOAD_EMPRESAS_SUCCESS = "LOAD_EMPRESAS_SUCCESS";
export const LOAD_EMPRESAS_FAIL = "LOAD_EMPRESAS_FAIL";
export const CLEAR_EMPRESAS = "CLEAR_EMPRESAS"; 

export const LOAD_EMPRESAS_ACCESO_USUARIO_START = "LOAD_EMPRESAS_ACCESO_USUARIO_START";
export const LOAD_EMPRESAS_ACCESO_USUARIO_SUCCESS = "LOAD_EMPRESAS_ACCESO_USUARIO_SUCCESS";
export const LOAD_EMPRESAS_ACCESO_USUARIO_FAIL = "LOAD_EMPRESAS_ACCESO_USUARIO_FAIL";

export const LOAD_USUARIOS_POR_CLIENTE_START =
  "LOAD_USUARIOS_POR_CLIENTE_START";
export const LOAD_USUARIOS_POR_CLIENTE_SUCCESS =
  "LOAD_USUARIOS_POR_CLIENTE_SUCCESS";
export const LOAD_USUARIOS_POR_CLIENTE_FAIL = "LOAD_USUARIOS_POR_CLIENTE_FAIL";
export const CLEAR_USUARIOS_POR_CLIENTE = "CLEAR_USUARIOS_POR_CLIENTE";

export const OPEN_MODAL_USER_CONFIG = "OPEN_MODAL_USER_CONFIG";
export const CLOSE_MODAL_USER_CONFIG = "CLOSE_MODAL_USER_CONFIG";

export const LOAD_VENDEDORES_POR_EMPRESA_START =
  "LOAD_VENDEDORES_POR_EMPRESA_START";
export const LOAD_VENDEDORES_POR_EMPRESA_SUCCESS =
  "LOAD_VENDEDORES_POR_EMPRESA_SUCCESS";
export const LOAD_VENDEDORES_POR_EMPRESA_FAIL =
  "LOAD_VENDEDORES_POR_EMPRESA_FAIL";

export const LOAD_CHANGE_LOGS_START = "LOAD_CHANGE_LOGS_START";
export const LOAD_CHANGE_LOGS_SUCCESS = "LOAD_CHANGE_LOGS_SUCCESS";
export const LOAD_CHANGE_LOGS_FAIL = "LOAD_CHANGE_LOGS_FAIL";

export const EXECUTE_CREATE_CHANGE_LOG = "EXECUTE_CREATE_CHANGE_LOG";
export const EXECUTE_UPDATE_CHANGE_LOG = "EXECUTE_UPDATE_CHANGE_LOG";
export const EXECUTE_DELETE_CHANGE_LOG = "EXECUTE_DELETE_CHANGE_LOG";

export const LOAD_CLIENTES_START = "LOAD_CLIENTES_START";
export const LOAD_CLIENTES_SUCCESS = "LOAD_CLIENTES_SUCCESS";
export const LOAD_CLIENTES_FAIL = "LOAD_CLIENTES_FAIL";

export const CLIENT_SELECTED = "CLIENT_SELECTED";

export const LOAD_EMPRESAS_DEL_CLIENTE_START =
  "LOAD_EMPRESAS_DEL_CLIENTE_START";
export const LOAD_EMPRESAS_DEL_CLIENTE_SUCCESS =
  "LOAD_EMPRESAS_DEL_CLIENTE_SUCCESS";
export const LOAD_EMPRESAS_DEL_CLIENTE_FAIL = "LOAD_EMPRESAS_DEL_CLIENTE_FAIL";
export const CLEAR_EMPRESAS_DEL_CLIENTE = "CLEAR_EMPRESAS_DEL_CLIENTE";

export const LOAD_USUARIOS_DEL_CLIENTE_START =
  "LOAD_USUARIOS_DEL_CLIENTE_START";
export const LOAD_USUARIOS_DEL_CLIENTE_SUCCESS =
  "LOAD_USUARIOS_DEL_CLIENTE_SUCCESS";
export const LOAD_USUARIOS_DEL_CLIENTE_FAIL = "LOAD_USUARIOS_DEL_CLIENTE_FAIL";

export const LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_START =
  "LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_START";
export const LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_SUCCESS =
  "LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_SUCCESS";
export const LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_FAIL =
  "LOAD_EMPRESAS_EIFFEL_DEL_CLIENTE_FAIL";

export const EXECUTE_CREATE_EMPRESA = "EXECUTE_CREATE_EMPRESA";

export const LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_START =
  "LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_START";
export const LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_SUCCESS =
  "LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_SUCCESS";
export const LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_FAIL =
  "LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_FAIL";

export const EXECUTE_CREATE_CLIENTE = "EXECUTE_CREATE_CLIENTE";
export const ACTUALIZAR_CLIENTE_CREADO = "ACTUALIZAR_CLIENTE_CREADO";

export const SET_USER_INFO = "SET_USER_INFO";

export const SHOW_GLOBAL_MODAL = "SHOW_GLOBAL_MODAL";
export const CHANGE_GLOBAL_MODAL_BODY = "CHANGE_GLOBAL_MODAL_BODY";
export const HIDDEN_GLOBAL_MODAL = "HIDDEN_GLOBAL_MODAL";

//#region Permisos

export const LOAD_APLICACIONES_START = "LOAD_APLICACIONES_START";
export const LOAD_APLICACIONES_SUCCESS = "LOAD_APLICACIONES_SUCCESS";
export const LOAD_APLICACIONES_FAIL = "LOAD_APLICACIONES_FAIL";
export const CLEAR_APLICACIONES = "CLEAR_APLICACIONES";

export const LOAD_SECCIONES_START = "LOAD_SECCIONES_START";
export const LOAD_SECCIONES_SUCCESS = "LOAD_SECCIONES_SUCCESS";
export const LOAD_SECCIONES_FAIL = "LOAD_SECCIONES_FAIL";
export const CLEAR_SECCIONES = "CLEAR_SECCIONES";

export const LOAD_PLANES_START = "LOAD_PLANES_START";
export const LOAD_PLANES_SUCCESS = "LOAD_PLANES_SUCCESS";
export const LOAD_PLANES_FAIL = "LOAD_PLANES_FAIL";
export const CLEAR_PLANES = "CLEAR_PLANES";

export const LOAD_PERFILES_START = "LOAD_PERFILES_START";
export const LOAD_PERFILES_SUCCESS = "LOAD_PERFILES_SUCCESS";
export const LOAD_PERFILES_FAIL = "LOAD_PERFILES_FAIL";
export const CLEAR_PERFILES = "CLEAR_PERFILES";

export const LOAD_PERFILES_CLIENTE_START = "LOAD_PERFILES_CLIENTE_START";
export const LOAD_PERFILES_CLIENTE_SUCCESS = "LOAD_PERFILES_CLIENTE_SUCCESS";
export const LOAD_PERFILES_CLIENTE_FAIL = "LOAD_PERFILES_CLIENTE_FAIL";
export const CLEAR_PERFILES_CLIENTE = "CLEAR_PERFILES_CLIENTE";

export const LOAD_PERFILES_CLIENTE_ASIGNACION_START = "LOAD_PERFILES_CLIENTE_ASIGNACION_START";
export const LOAD_PERFILES_CLIENTE_ASIGNACION_SUCCESS = "LOAD_PERFILES_CLIENTE_ASIGNACION_SUCCESS";
export const LOAD_PERFILES_CLIENTE_ASIGNACION_FAIL = "LOAD_PERFILES_CLIENTE_ASIGNACION_FAIL";
export const CLEAR_PERFILES_CLIENTE_ASIGNACION = "CLEAR_PERFILES_CLIENTE_ASIGNACION";

//#endregion

//#region Notificaciones

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

//#endregion