import React from "react";
import MaterialTable from "material-table";

const Table = (props) => {
  const {
    title,
    columns,
    data,
    loading,
    actions,
    components,
    customOptions,
    actionsTitle,
    editable,
    parentChildData,
  } = props;

  return (
    <MaterialTable
      title={title}
      columns={columns}
      editable={{
        ...editable,
      }}
      data={data}
      isLoading={loading}
      actions={actions}
      components={components}
      parentChildData={parentChildData}
      localization={{
        header: {
          actions: actionsTitle,
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "Filas",
          previousTooltip: "Página anterior",
          nextTooltip: "Página siguiente",
          firstTooltip: "Primer página",
          lastTooltip: "Última página",
        },
        toolbar: {
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        body: {
          editTooltip: "Editar",
          addTooltip: "Nuevo",
          editRow: {
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar cambios",
          },
          emptyDataSourceMessage: "No hay datos para mostrar",
        },
      }}
      options={
        customOptions
          ? customOptions
          : {
              actionsColumnIndex: !props.actionsInFirstColumn ? -1 : 0,
              grouping: false,
              draggable: false,
              columnResizable: false,
              tableLayout: "auto",
              rowStyle:{
                fontSize: 14,
                padding: 0
              },
              padding:"dense",
              headerStyle:{
                padding:0
              }
            }
      }
    />
  );
};

export default Table;
