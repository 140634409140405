import React, { Fragment, useEffect, useState } from "react";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { Checkbox, IconButton, Typography } from "@material-ui/core";

import ModalUserAppsConfig from "../Configuraciones/ModalUserAppsConfig/ModalUserAppsConfig";
import Table from "../UI/Table/Table";
import ModalFormUpdateUser from "../Configuraciones/ModalFormUpdateUser/ModalFormUpdateUser";
import classes from "./ListUsersDataTable.css";
import BusinessIcon from '@material-ui/icons/Business';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined'

const ListUsersDataTable = (props) => {
  const {
    onLoadUsuarios,
    onLoadEmpresas,
    onLoadPerfiles,
    empresas,
    usuarios,
    loadingEmpresas,
    loadingUsuarios,
    loadingPerfiles,
    onOpenModal,
  } = props;

  const [user, setUser] = useState({
    id: "",
    name: "",
    surname: "",
    email: "",
    baja: false,
    perfilid: ''
  });

  const [showUpdateUser, setShowUpdateUser] = useState(false);

  const handleCheckboxClick = (event, rowData) => {
    rowData.baja = event.target.checked;
  };

  const cellStyles = {    
    padding:0,
    height:42, 
    width: 50,
    fontSize: '12px',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyles = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const buttonHeaderStyles = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: 'white',
    textAlign: 'center',
  };

  const buttonCellStyles = {  
    padding:0,
    height:42, 
    width: 20,
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: 'white',
    textAlign: 'center'
  }

  const getColumns = () => {
    return [
      {
        title: "Nombre",
        field: "name",
        validate: (rowData) => {
          return rowData.name === "" ? "*Campo requerido" : "";
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Apellido",
        field: "surname",
        validate: (rowData) => {
          return rowData.name === "" ? "*Campo requerido" : "";
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Email",
        field: "email",
        validate: (rowData) => {
          return rowData.email === "" ? "*Campo requerido" : "";
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Baja",
        field: "baja",
        type: "boolean",
        render: (rowData) => { return (
          <Checkbox color="primary" size="small" checked={rowData.baja} disabled={true} onChange={(event) => handleCheckboxClick(event, rowData)}/>
        )},
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Perfil",
        field: "perfilid",
        render: (rowData) => {
          const perfilNombre = rowData.perfil && !rowData.perfil.demo && !rowData.perfil.gauss 
            ? rowData.perfil.nombre : '';

          return (
            <Typography>
              {perfilNombre}
            </Typography>
          )
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Empresas",
        field: "dashboard",
        editable: "never",
        render: (rowData) => { return (
          <IconButton 
            style={{ maxWidth: "20px", maxHeight: '20px', paddingInline: '5px', paddingBlock: '2px' }}
            onClick={() => onOpenModal(rowData.id, rowData.email, "Empresas", empresas)}
          >
            <BusinessIcon color="primary" />
          </IconButton>
        )},
        headerStyle: buttonHeaderStyles,
        cellStyle: buttonCellStyles
      },
      {
        title: "Vendedor",
        field: "vendedores",
        editable: "never",
        render: (rowData) => { return rowData.perfil && rowData.perfil.vendedor 
          ? (
            <IconButton 
              style={{ maxWidth: "20px", maxHeight: '20px', paddingInline: '5px', paddingBlock: '2px' }}
              onClick={() => onOpenModal(rowData.id, rowData.email, "Vendedor")}            
            >
              <WorkOutlineOutlinedIcon color="primary" />
            </IconButton>
          ) : (
            <Fragment></Fragment>
          )
        },
        headerStyle: buttonHeaderStyles,
        cellStyle: buttonCellStyles
      },
    ]
  }

  useEffect(() => {
    onLoadUsuarios();
  }, [onLoadUsuarios]);

  useEffect(() => {
    onLoadEmpresas(0);
  }, [onLoadEmpresas]);

  useEffect(() => {
    onLoadPerfiles();
  }, [onLoadPerfiles]);

  const data = () => {
    return usuarios.map((usuario) => ({
      id: usuario.id,
      name: usuario.nombre,
      surname: usuario.apellido,
      email: usuario.email,
      baja: usuario.baja,
      perfilid: usuario.perfilId,
      perfil: usuario.perfil,
    }));
  };

  const inLoading = loadingUsuarios || loadingEmpresas || loadingPerfiles;
  return (
    <div className={classes.ListUserDataTableContainer}>
      <Table className={classes.ListUserTable}
        title={<span className={classes.ListUserTableTitle}>Configuración de usuarios y asignación de perfiles</span>}
        columns={getColumns()}
        data={data()}
        loading={inLoading}
        actions={[
          {
            icon: "edit",
            iconProps: {style: {color: "#00BCD4"}},
            tooltip: "Modificar usuario",
            onClick: (event, rowData) => {
              setUser(rowData);
              setShowUpdateUser(true);
            },
          },
        ]}
        actionsInFirstColumn
      />
      {props.showAppsConfig && <ModalUserAppsConfig />}
      {showUpdateUser && (
        <ModalFormUpdateUser
          id={user.id}
          name={user.name}
          email={user.email}
          user={user}
          show={showUpdateUser}
          onClose={() => setShowUpdateUser(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingUsuarios: state.usuarios.usuariosPorCliente.loading,
    usuarios: state.usuarios.usuariosPorCliente.usuarios,
    showAppsConfig: state.configuraciones.dashboardUserConfig.show,
    clienteId: state.clientes.clientSelected.clienteId,
    clienteIdPg: state.clientes.clientSelected.clienteIdPg,
    perfiles: state.permisos.loadPerfilesClienteAsignacion.items,
    loadingPerfiles: state.permisos.loadPerfilesClienteAsignacion.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadPerfiles: () => dispatch(actions.loadPerfilesClienteAsignacion()),
    onLoadUsuarios: () => dispatch(actions.loadUsuariosPorCliente()),
    onLoadEmpresas:(clienteId) => dispatch(actions.loadEmpresasDelCliente(clienteId)),
    onOpenModal: (userId, userEmail, appConfig) => dispatch(actions.openModalUserConfig(userId, userEmail, appConfig)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUsersDataTable);
